import React from 'react';
import './HeroTitle.scss';

export default class HeroTitle extends React.Component {
  constructor(props) {
    // use this just to initialize the state but not load the data!
    super(props);
    
    this.state = {
      // name: "some name"
    };
  }

  componentDidMount() {
    // this component just fully mounted
    // load the data here
      // this.setState({ name: "updated name"});
  }

  componentDidUpdate() {
    // this component just updated
  }

  renderContent(){
    return <div className='hero-title-container'  style={this.props.style} >
      <div className='hero-title'>
        <p class="title-part1">om</p>
        <p class="title-part2">rama</p>
        <p class="title-part3">studio</p>
      </div>
    </div>
  }


  render() {
    // use this to just load JSX
    return this.renderContent();
  }

}

