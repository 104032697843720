import React from 'react';
import './AppPage.scss';

export default class AppPage extends React.Component {
  constructor(props) {
    // use this just to initialize the state but not load the data!
    super(props);
    
    this.state = {
      name: "some name"
    };
  }

  componentDidMount() {
    // this component just fully mounted
    // load the data here
      this.setState({ name: "updated name"});
  }

  componentDidUpdate() {
    // this component just updated
  }

  renderContent(){
    return <div className='app-page'  style={this.props.style} >
      {this.props.children}
    </div>
  }


  render() {
    // use this to just load JSX
    return this.renderContent();
  }

}

