import React from 'react';
import './SocialButton.scss';

export default class SocialButton extends React.Component {
  
  constructor(props) {
    // use this just to initialize the state but not load the data!
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    // this component just fully mounted
    // load the data here
      // this.setState({ 
      //   name: this.props.name,
      //   filepath: this.props.filepath,
      //   link: this.props.link
      // });
  }

  componentDidUpdate() {
    // this component just updated
  }

  renderContent(){
    return <div className='social-button'  style={this.props.style} >
      <a href={this.props.icon.link} target='_blank'>
        <i class="icon">
          <img 
            src={this.props.icon.file} 
            alt={this.props.icon.name} 
            className='svg_image'
          />
        </i>
      </a>
    </div>
  }


  render() {
    // use this to just load JSX
    return this.renderContent();
  }

}

