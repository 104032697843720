import holi from './holi.jpeg'
import poli from './poli.jpeg'
import han_1 from './han_1.JPG'
import grad1 from './grad1.jpg'

import dj from './dj.jpg'
import mothers from './mothers.jpg'
import mother_portrait from './mother_portrait.jpg'


const hero_photoslider_images = [
  {
    name: 'mothers',
    file: mothers,
    main: true,
  },
  {
    name: 'dj',
    file: dj,
    main: false,
  },
  {
    name: 'mother_portrait',
    file: mother_portrait,
    main: false,
  },
  {
    name: 'holi',
    file: holi,
    main: true,
  },
  {
    name: 'poli',
    file: poli,
    main: false,
  },
  {
    name: 'grad1',
    file: grad1,
    main: false,
  },
  {
    name: 'han_1',
    file: han_1,
    main: false,
  },
]

export default hero_photoslider_images;
