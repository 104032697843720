import React from 'react';
import AppPage from '../shared/AppPage';
import './HomePage.scss'
import FullScreenSection from '../shared/FullScreenSection';
import PhotoCarousel from '../shared/PhotoCarousel';
import SocialButtonsContainer from '../shared/SocialButtonsContainer';
import HeroTitle from '../shared/HeroTitle';

export default class HomePage extends React.Component {
  constructor(props) {
    // use this just to initialize the state but not load the data!
    super(props);
    
    this.state = {
      name: "some name"
    };
  }

  componentDidMount() {
    // this component just fully mounted
    // load the data here
      this.setState({ name: "updated name"});
  }

  componentDidUpdate() {
    // this component just updated
  }


  renderContent(){

    const heroSectionCustomStyle = { 
      // background: 'black' 
      position: 'relative'
    };
    const contactCustomStyle = { 
      backdropFilter: 'blur(5px)',
      backgroundImage: 'linear-gradient(135deg, teal 10%, rgba(183, 119, 0, 0.9) 100%)',
    };

    const SocialButtonsContainerCustomStyle = {

    };

    const PhotoCarouselCustomStyle = {
      position: 'absolute'
    };
    
    const HeroTitleStyle = {
      position: 'absolute'
    };

    return <div className='home-page'>
      <AppPage>

        <FullScreenSection style={heroSectionCustomStyle} className="hero-section">
          <PhotoCarousel style={PhotoCarouselCustomStyle}>

          </PhotoCarousel>
          <HeroTitle style={HeroTitleStyle}>
            
          </HeroTitle>
        </FullScreenSection>
        
        <FullScreenSection style={contactCustomStyle} className="contact-section">
          <SocialButtonsContainer style={SocialButtonsContainerCustomStyle}>

          </SocialButtonsContainer>
        </FullScreenSection>
      </AppPage>
    </div>
  }

  render() {
    // use this to just load JSX
    return <div>
      {this.renderContent()}
    </div>;
  }
}

