import facebook from './facebook.svg'
import whatsapp from './whatsapp.svg'

import instagram from './instagram.svg'
import messenger from './messenger.svg'
import telegram from './telegram.svg'
import youtube from './youtube.svg'


const social_icons = [
  
  {
    name: 'instagram',
    file: instagram,
    link: 'https://www.instagram.com/omramastudio/'
  },
  //   {
  //   name: 'telegram',
  //   file: telegram,
  //   link: ''
  // },
  {
    name: 'youtube',
    file: youtube,
    link: 'https://www.youtube.com/@OmRamaStudio'
  },
  {
    name: 'facebook',
    file: facebook,
    link: 'https://www.facebook.com/omramastudio'
  },
]

export default social_icons;
