import React from 'react';
import './PhotoCarousel.scss';
import images from '../../images/hero_photo_slider_images'

export default class PhotoCarousel extends React.Component {
  constructor(props) {
    // use this just to initialize the state but not load the data!
    super(props);
    
    this.state = {
      // name: "some name"
      images: images
    };
  }

  componentDidMount() {
    // this component just fully mounted
    // load the data here
      // this.setState({ name: "updated name"});
  }

  componentDidUpdate() {
    // this component just updated
  }

  renderContent(){

    return <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <div class="carousel-inner">
    {
      this.state.images.sort(() => Math.random() - 0.5).map((i, index) => {
        return <div class={`carousel-item ${i.main ? "active" : ""}`}>
          <img src={i.file} class="d-block w-100 c-img" alt={i.name} ></img>
        </div>
      })
    }
    </div>

    <button class="carousel-control-prev control" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next control" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>




  }


  render() {
    // use this to just load JSX
    return this.renderContent();
  }

}

