import React from 'react';
import SocialButton from './SocialButton';
import social_icons from '../../icons/social_icons'
import './SocialButtonsContainer.scss';

export default class SocialButtonsContainer extends React.Component {
  
  constructor(props) {
    // use this just to initialize the state but not load the data!
    super(props);
    
    this.state = {
      icons: social_icons
    };
  }

  componentDidMount() {
    // this component just fully mounted
    // load the data here
      // this.setState({ name: "updated name"});
  }

  componentDidUpdate() {
    // this component just updated
  }

  renderContent(){
    return <div className='outer-container'>
        <div className='social-buttons-container'  style={this.props.style} >
        { 
          this.state.icons.map((i, index) => {
            return <SocialButton icon={i} key={index}></SocialButton>
          })
        }
      </div>
    </div>
  }

  render() {
    // use this to just load JSX
    return this.renderContent();
  }

}

